/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import { Form, Image } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  createCategorySetup, getCategorySetup, listCategorySetup, updateCategorySetup,
} from 'redux-store/categorySetup/categorySetupActions';
import { clearData } from 'redux-store/categorySetup/categorySetupSlice';
import Button from 'shared/components/button/Button';
import CheckBox from 'shared/components/checkbox/CheckBox';
import DatePicker from 'shared/components/datePicker/DatePicker';
import InputField from 'shared/components/inputField/InputField';
import { isNew } from 'shared/utils/global';
import { applyTrim, blackListedChars, emptyFieldValidator } from 'shared/utils/validators';
import '../categorySetupList/_categorySetup.scss';
import { getTermedFiltersOnField } from 'shared/utils/filters';
import UploadButton from 'shared/components/upload/Upload';

export default function AddNewCategorySetup() {
  const { id } = useParams();
  const { isLoading, selectedCategorySetup } = useSelector((s) => s.categorySetup);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [imageSrc, setImageSrc] = useState(null);

  const goBack = () => {
    form.resetFields();
    navigate(-1);
  };
  const handleSubmit = (vals) => {
    vals = applyTrim(vals);
    if (id === 'new') {
      dispatch(createCategorySetup(vals)).then((payload) => {
        if (_.isEmpty(payload.error)) {
          goBack();
        }
      });
    } else {
      dispatch(updateCategorySetup({ id, data: vals }));
    }
  };

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getCategorySetup(id));
    }
    dispatch(listCategorySetup({
      limit: 0,
      ...getTermedFiltersOnField('procedureCategoryEffectiveDate', 'procedureCategoryTermDate'),
    }));
    return () => dispatch(clearData());
  }, []);

  useEffect(() => {
    if (!_.isEmpty(selectedCategorySetup)) {
      setImageSrc(null);
      if (id && selectedCategorySetup?.categoryIcon?.url) {
        setImageSrc(selectedCategorySetup?.categoryIcon?.url);
      }
      form.setFieldsValue({
        ...selectedCategorySetup,
        categoryIcon: selectedCategorySetup?.categoryIcon?.id,
      });
    }
  }, [selectedCategorySetup]);

  return (
    <div className="add-category-setup-container">
      <div className="add-category-setup-container__body">
        <h4>
          {isNew(id) ? 'Add New Category' : 'Edit Category'}
        </h4>
        <Form onFinish={handleSubmit} form={form} onFinishFailed={() => toast.error('Please fill the entire form')}>
          <div className="add-category-setup-container__body--input-field-wrapper">
            <div className="label-input-wrapper">
              <Form.Item
                name="procedureCategoryName"
                className="formfield"
                rules={[
                  { validator: emptyFieldValidator, message: 'This field is required' },
                  blackListedChars,
                ]}
              >
                <InputField
                  label="Category Name *"
                  placeholder="Category Name"
                />
              </Form.Item>
            </div>
            <div className="label-input-wrapper">
              <Form.Item
                name="procedureCategoryEffectiveDate"
                className="formfield"
                rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
                getValueProps={(i) => ({ value: i && moment(i) })}
              >
                <DatePicker label="Category Effective Date *" />
              </Form.Item>
            </div>
            <div className="label-input-wrapper">
              <Form.Item
                name="procedureCategoryTermDate"
                className="formfield"
                getValueProps={(i) => ({ value: i && moment(i) })}
              >
                <DatePicker
                  label="Category Term Date "
                  disabledDate={(d) => moment(d).isBefore(form.getFieldValue('procedureCategoryEffectiveDate'))}
                />
              </Form.Item>
            </div>
          </div>
          <div className="add-category-setup-container__body--input-field-wrapper">
            <div className="label-input-wrapper">
              <Form.Item
                name="isRevPrivate"
                rules={[
                  // { validator: emptyFieldValidator, message: 'This Field is required' },
                ]}
                className="formfield"
                // style={{ display: 'flex' }}
                valuePropName="checked"
              >
                <CheckBox label="Required for RevPrivate" />
              </Form.Item>
            </div>
            <div className="label-input-wrapper">
              <Form.Item
                name="categoryIcon"
                className="formfield"
                rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
              >
                <UploadButton
                  accept=".jpg, .jpeg, .png"
                  label="Category Icon *"
                  placeholder="Upload Icon"
                  onChange={(e) => setImageSrc(URL.createObjectURL(e))}
                />
              </Form.Item>
              {imageSrc && (
                <Image
                  src={imageSrc}
                />
              )}
            </div>
            <div className="label-input-wrapper" />
          </div>
          <div className="add-category-setup-container__body--btns-wrapper">
            <div>
              <Button variant={Button.variant.outlined} onClick={goBack}>Cancel</Button>
            </div>
            {!isNew(id) && (
              <div className="updated-date-wrapper">
                <span>Last Updated:</span>
                <span className="orange">{moment(selectedCategorySetup.updatedAt).format('MM/DD/YYYY hh:mm A')}</span>
              </div>
            )}
            <div>
              <Button isLoading={isLoading} variant={Button.variant.filled} htmlType="submit">{isNew(id) ? 'Save' : 'Update'}</Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}
