import moment from 'moment';

export const getChecklists = (selectedMyProviders) => {
  let verificationCheckList = {};
  if (!selectedMyProviders.verificationChecklist) {
    return verificationCheckList;
  }

  verificationCheckList = Object.entries(selectedMyProviders.verificationChecklist)
    .reduce((acc, [k, v]) => {
      acc[k] = { ...v, verifiedById: v?.verifiedById?.id };
      return acc;
    }, {});
  verificationCheckList.hipaaTraining = {};
  if (selectedMyProviders?.isVetProvider) {
    verificationCheckList.hipaaTraining = { verified: true };
    verificationCheckList.supervisorCheck = { verified: true };
  } else if (selectedMyProviders.completedHipaaTrainingDates
        && selectedMyProviders.completedHipaaTrainingDates?.length > 0
        && selectedMyProviders.completedHipaaTrainingDates[0].date
        && moment(selectedMyProviders.completedHipaaTrainingDates[0].date).add(1, 'year').isSameOrAfter(moment())) {
    verificationCheckList.hipaaTraining = {
      verified: true,
      timestamp: selectedMyProviders.completedHipaaTrainingDates[0].date,
    };
  } else {
    verificationCheckList.hipaaTraining = { verified: false };
  }
  verificationCheckList.henryScheinOrderSetup = {};
  if (selectedMyProviders?.deliveryAddresses
        && selectedMyProviders?.deliveryAddresses?.length > 0
        && selectedMyProviders?.deliveryAddresses?.findIndex((x) => x.hsAccountNumber !== null
        && x.hsAccountNumber !== '' && x.hsAccountNumber !== undefined) !== -1) {
    verificationCheckList.henryScheinOrderSetup = {
      verified: true,
      timestamp: selectedMyProviders?.deliveryAddresses[0].updatedAt
        || selectedMyProviders?.deliveryAddresses[0].createdAt,
    };
  } else {
    verificationCheckList.henryScheinOrderSetup = { verified: false };
  }

  verificationCheckList.profileComplete = {};
  if (selectedMyProviders?.biography?.gender && selectedMyProviders?.biography?.selfDescription
    && selectedMyProviders?.biography?.providerVideo?.url
    && selectedMyProviders?.biography?.providerVideo?.deletedInfoField?.isDeleted === false
    && selectedMyProviders?.biography?.providerPhoto?.url
    && selectedMyProviders?.biography?.providerPhoto?.deletedInfoField?.isDeleted === false
    && selectedMyProviders?.biography?.languagesSpoken
    && selectedMyProviders?.biography?.languagesSpoken?.findIndex(
      (x) => x.deletedInfoField?.isDeleted === false,
    ) !== -1) {
    const dates = [selectedMyProviders?.biography?.providerVideo.updatedAt
      || selectedMyProviders?.biography?.providerVideo.createdAt,
    selectedMyProviders?.biography?.providerPhoto.updatedAt
      || selectedMyProviders?.biography?.providerPhoto.createdAt,
    /* eslint-disable-next-line no-unsafe-optional-chaining */
    ...selectedMyProviders?.biography?.languagesSpoken?.map((x) => x?.updatedAt || x?.createdAt)];
    const filterDate = dates?.filter((x) => x !== undefined);
    verificationCheckList.profileComplete = {
      verified: true,
      timestamp: filterDate?.length > 0 && filterDate?.reduce((a, b) => (a > b ? a : b)),
    };
  } else {
    verificationCheckList.profileComplete = { verified: false };
  }

  return verificationCheckList;
};
